import { useEffect } from 'react';

declare global {
	interface Window {
		CookieConsent: {
			renew: () => void;
		};
		CookieInformation: {
			getConsentGivenFor: (category: string) => boolean;
		};
	}
}

export type CookieConsentTypes = { statistic: boolean; functional: boolean; marketing: boolean };
export interface CookieConsentProps {
	children?: React.ReactNode;
	consentCallBack: ({ statistic, functional, marketing }: CookieConsentTypes) => void;
}

export const CookieConsent = ({ children, consentCallBack }: CookieConsentProps) => {
	useEffect(() => {
		const handleConsent = () => {
			const consent = {
				statistic: false,
				functional: false,
				marketing: false,
			};
			if (window.CookieInformation.getConsentGivenFor('cookie_cat_statistic')) {
				consent.statistic = true;
			}
			if (window.CookieInformation.getConsentGivenFor('cookie_cat_functional')) {
				consent.functional = true;
			}
			if (window.CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
				consent.marketing = true;
			}
			consentCallBack(consent);
		};
		window.addEventListener('CookieInformationConsentGiven', handleConsent);
		return () => {
			window.removeEventListener('CookieInformationConsentGiven', handleConsent);
		};
	}, [consentCallBack]);

	// Todo: block cookie dependent children here instead of doing it in Vendors
	return <>{children}</>;
};
