import classNames from 'classnames';
import Checkmark from 'icons/ok.svg';
import styles from './LanguageSelectorItem.module.scss';
import Globe from 'icons/sprogvaelger.svg';

export interface LanguageSelectorItemProps {
	children?: React.ReactNode;
	selected?: boolean;
	globe?: boolean;
}

export const LanguageSelectorItem: React.FC<LanguageSelectorItemProps> = ({ children, selected, globe }) => {
	return (
		<li className={classNames(styles.LanguageSelectorItem, selected && styles.LanguageSelectorItem___selected)}>
			{globe && <Globe className={styles.LanguageSelectorItem_globe} />}
			{selected && <Checkmark className={styles.LanguageSelectorItem_checkmark} aria-hidden={true} />} {children}
		</li>
	);
};
