import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { customizeElement } from 'utilities/customizeElement';
import styles from './NavigationCollapse.module.scss';

export interface NavigationCollapseProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items: any[];
	setStyle: () => void;
	openText: string;
	closeText: string;
	callback: () => void;
}

export const NavigationCollapse: React.FC<NavigationCollapseProps> = ({ items, setStyle, openText, closeText, callback }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<button
				className={classNames(styles.NavigationCollapse_collapser, { [styles.NavigationCollapse_collapser___open]: open })}
				onClick={() => {
					setOpen(!open), setStyle();
				}}
				aria-label={open ? openText : closeText}
			></button>
			{open && (
				<ul className={styles.NavigationCollapse_list}>
					{items.map((item, index) => (
						<li
							className={classNames(styles.NavigationCollapse_item, { [styles.NavigationCollapse_item___open]: open })}
							key={index}
							aria-hidden={!open}
						>
							<span onClick={callback}>{customizeElement({ element: item.link, style: styles.NavigationCollapse_link })}</span>
						</li>
					))}
				</ul>
			)}
		</>
	);
};
