import dynamic from 'next/dynamic';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import logger from 'utilities/logger';
import { capitalizeFirstLetter } from 'utilities/string';
export interface ModuleFeatureProps {
	contentModule: Content.ContentModule | Content.GridModule;
	isHeader?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	additionalProps?: any;
	area?: 'contentSection' | 'topBanner';
	className?: string;
}

const features = {
	AlertFeature: dynamic<Content.ContentModule>(() => import('../Alert').then((module) => module.AlertFeature)),
	AnchorFeature: dynamic<Content.ContentModule>(() => import('../Anchor').then((module) => module.AnchorFeature)),
	ArticleHeaderFeature: dynamic<Content.ContentModule>(() => import('../ArticleHeader').then((module) => module.ArticleHeaderFeature)),
	ArticleMediaFeature: dynamic<Content.ContentModule>(() => import('../ArticleMedia').then((module) => module.ArticleMediaFeature)),
	ArticleTextFeature: dynamic<Content.ContentModule>(() => import('../ArticleText').then((module) => module.ArticleTextFeature)),
	BannerCardFeature: dynamic<Content.ContentModule>(() => import('../BannerCard').then((module) => module.BannerCardFeature)),
	BottomTaxDeductionCalculatorFeature: dynamic<Content.ContentModule>(() =>
		import('../BottomTaxDeductionCalculator').then((module) => module.BottomTaxDeductionCalculatorFeature),
	),
	BreadcrumbFeature: dynamic<Content.ContentModule>(() => import('../Breadcrumb').then((module) => module.BreadcrumbFeature)),
	BusinessDeadlinesCalculatorFeature: dynamic(() =>
		import('../BusinessDeadlinesCalculator').then((module) => module.BusinessDeadlinesCalculatorFeature),
	),
	CountyTaxTableFeature: dynamic<Content.ContentModule>(() => import('../CountyTaxTable').then((module) => module.CountyTaxTableFeature)),
	CalculatorFeature: dynamic<Content.ContentModule>(() => import('../Calculator').then((module) => module.CalculatorFeature)),
	CalculatorSelectorFeature: dynamic<Content.ContentModule>(() =>
		import('../CalculatorSelector').then((module) => module.CalculatorSelectorFeature),
	),
	CollapseFeature: dynamic<Content.ContentModule>(() => import('../Collapse').then((module) => module.CollapseFeature)),
	CollapseCollectionFeature: dynamic<Content.ContentModule>(() =>
		import('../CollapseCollection').then((module) => module.CollapseCollectionFeature),
	),
	CollapseImageFeature: dynamic<Content.ContentModule>(() => import('../CollapseImage').then((module) => module.CollapseImageFeature)),
	RentalMeansOfTransportationFeature: dynamic(() =>
		import('../RentalMeansOfTransportation').then((module) => module.RentalMeansOfTransportationFeature),
	),
	CollapseTextFeature: dynamic<Content.ContentModule>(() => import('../CollapseText').then((module) => module.CollapseTextFeature)),
	ComparisonTableFeature: dynamic<Content.ContentModule>(() => import('../ComparisonTable').then((module) => module.ComparisonTableFeature)),
	ComparisonFilterTableFeature: dynamic<Content.ContentModule>(() =>
		import('../ComparisonFilterTable').then((module) => module.ComparisonFilterTableFeature),
	),
	ContactBoxFeature: dynamic<Content.ContentModule>(() => import('../ContactBox').then((module) => module.ContactBoxFeature)),
	ContentSectionFeature: dynamic<Content.ContentModule>(() => import('../ContentSection').then((module) => module.ContentSectionFeature)),
	CustomsCalculatorFeature: dynamic<Content.ContentModule>(() => import('../CustomsCalculator').then((module) => module.CustomsCalculatorFeature)),
	DebtApplicationFeature: dynamic<Content.ContentModule>(() => import('../DebtApplication').then((module) => module.DebtApplicationFeature)),
	DownloadButtonFeature: dynamic<Content.ContentModule>(() => import('../DownloadButton').then((module) => module.DownloadButtonFeature)),
	ExampleBoxFeature: dynamic<Content.ContentModule>(() => import('../ExampleBox').then((module) => module.ExampleBoxFeature)),
	ExciseDutyCalculatorFeature: dynamic<Content.ContentModule>(() => import('../ExciseDuty').then((module) => module.ExciseDutyCalculatorFeature)),
	FeedbackCardFeature: dynamic<Content.ContentModule>(() => import('../FeedbackCard').then((module) => module.FeedbackCardFeature)),
	FlowModuleFeature: dynamic<Content.ContentModule>(() => import('../FlowModule').then((module) => module.FlowModuleFeature)),
	FocusBoxFeature: dynamic<Content.ContentModule>(() => import('../FocusBox').then((module) => module.FocusBoxFeature)),
	FooterCardFeature: dynamic<Content.ContentModule>(() => import('../FooterCard').then((module) => module.FooterCardFeature)),
	FooterLinksFeature: dynamic<Content.ContentModule>(() => import('../FooterLinks').then((module) => module.FooterLinksFeature)),
	GreenTaxCalculatorFeature: dynamic<Content.ContentModule>(() =>
		import('../GreenTaxCalculator').then((module) => module.GreenTaxCalculatorFeature),
	),
	GridColumnsFeature: dynamic<Content.ContentModule>(() => import('../GridColumns').then((module) => module.GridColumnsFeature)),
	HeadingCardFeature: dynamic<Content.ContentModule>(() => import('../HeadingCard').then((module) => module.HeadingCardFeature)),
	HeaderCardFeature: dynamic<Content.ContentModule>(() => import('../HeaderCard').then((module) => module.HeaderCardFeature)),
	HeroCardFeature: dynamic<Content.ContentModule>(() => import('../HeroCard').then((module) => module.HeroCardFeature)),
	HeroImageFeature: dynamic<Content.ContentModule>(() => import('../HeroImage').then((module) => module.HeroImageFeature)),
	IFrameFeature: dynamic<Content.ContentModule>(() => import('../IFrame').then((module) => module.IFrameFeature)),
	ImageContentFeature: dynamic<Content.ContentModule>(() => import('../ImageContent').then((module) => module.ImageContentFeature)),
	JobTeaserModuleFeature: dynamic<Content.ContentModule>(() => import('../JobTeaserModule').then((module) => module.JobTeaserModuleFeature)),
	LoginFeature: dynamic<Content.ContentModule>(() => import('../Login').then((module) => module.LoginFeature)),
	NewsletterSignupFeature: dynamic<Content.ContentModule>(() => import('../NewsletterSignup').then((module) => module.NewsletterSignupFeature)),
	NewsTeaserFeature: dynamic<Content.ContentModule>(() => import('../NewsTeaser').then((module) => module.NewsTeaserFeature)),
	PlateOperatorFeature: dynamic<Content.ContentModule>(() => import('../PlateOperator').then((module) => module.PlateOperatorFeature)),
	ProgressionListFeature: dynamic<Content.ContentModule>(() => import('../ProgressionList').then((module) => module.ProgressionListFeature)),
	CookiePolicyScriptFeature: dynamic<Content.ContentModule>(() =>
		import('../CookiePolicyScript').then((module) => module.CookiePolicyScriptFeature),
	),
	PublicationsTeaserFeature: dynamic<Content.ContentModule>(() =>
		import('../PublicationsTeaser').then((module) => module.PublicationsTeaserFeature),
	),
	ReadMoreFeature: dynamic<Content.ContentModule>(() => import('../ReadMore').then((module) => module.ReadMoreFeature)),
	SalaryAfterTaxFeature: dynamic<Content.ContentModule>(() => import('../SalaryAfterTax').then((module) => module.SalaryAfterTaxFeature)),
	SalaryWithholdingFeature: dynamic<Content.ContentModule>(() => import('../SalaryWithholding').then((module) => module.SalaryWithholdingFeature)),
	SharedContentModuleFeature: dynamic<Content.ContentModule>(() =>
		import('../SharedContentModule').then((module) => module.SharedContentModuleFeature),
	),
	SharedAsideContentModuleFeature: dynamic<Content.ContentModule>(() =>
		import('../SharedAsideContentModule').then((module) => module.SharedAsideContentModuleFeature),
	),
	ShortcutsFeature: dynamic<Content.ContentModule>(() => import('../Shortcuts').then((module) => module.ShortcutsFeature)),
	SiblingNavigationFeature: dynamic<Content.ContentModule>(() => import('../SiblingNavigation').then((module) => module.SiblingNavigationFeature)),
	SituationSelectorFeature: dynamic<Content.ContentModule>(() => import('../SituationSelector').then((module) => module.SituationSelectorFeature)),
	SocialLinksFeature: dynamic<Content.ContentModule>(() => import('../SocialLinks').then((module) => module.SocialLinksFeature)),
	StepGuideFeature: dynamic<Content.ContentModule>(() => import('../StepGuide').then((module) => module.StepGuideFeature)),
	TeaserItemFeature: dynamic<Content.ContentModule>(() => import('../TeaserItem').then((module) => module.TeaserItemFeature)),
	TeaserModuleFeature: dynamic<Content.ContentModule>(() => import('../TeaserModule').then((module) => module.TeaserModuleFeature)),
	TransportLinksFeature: dynamic<Content.ContentModule>(() => import('../TransportLinks').then((module) => module.TransportLinksFeature)),
	TravelDeductionFeature: dynamic<Content.ContentModule>(() => import('../TravelDeduction').then((module) => module.TravelDeductionFeature)),
	VideoFeature: dynamic<Content.ContentModule>(() => import('../Video').then((module) => module.VideoFeature)),
	QuizFeature: dynamic<Content.ContentModule>(() => import('../Quiz').then((module) => module.QuizFeature)),
};

export const ModuleFeature: React.FC<ModuleFeatureProps> = ({ contentModule, additionalProps, area, className }) => {
	const production = process.env.NODE_ENV === 'production';
	const { documentType } = contentModule;
	const moduleName = capitalizeFirstLetter(documentType?.split('DataCopy')[0]);
	const moduleFeatureName = `${moduleName}Feature`;

	const DynamicModule = features[moduleFeatureName];

	const message = `Component [${documentType}] is missing a React Component. Add missing export '${moduleName}' from '/features`;
	if (!DynamicModule && !production) {
		return <div>{message}</div>;
	}

	if (!DynamicModule && production) {
		logger.error(message);
		return null;
	}

	const errorHandler = (error: Error, info: { componentStack: string }) => {
		logger.error(`Error with component [${documentType}]: ${error}. ${info.componentStack}`);
	};

	return (
		<ErrorBoundary FallbackComponent={() => null} onError={errorHandler}>
			<DynamicModule {...contentModule} {...additionalProps} area={area} className={className} />
		</ErrorBoundary>
	);
};
