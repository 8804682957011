import { RichText } from 'components/1-atoms/RichText';
import styles from './LoginModalContent.module.scss';
export interface LoginModalContentProps {
	className?: string;
	link: JSX.Element;
	content?: string;
}

export const LoginModalContent = ({ link, content }: LoginModalContentProps) => {
	return (
		<>
			{link}
			<RichText className={styles.LoginModalContent_text} content={content} />
		</>
	);
};
