import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { usePageContext } from 'application/contexts/PageContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { SearchModal } from 'designsystem/components/2-molecules/SearchModal';
import { LinkFeature } from 'features/Modules/Link';
import { useEffect, useRef } from 'react';
import { CludoSearchInputFeature } from '../CludoSearchInput/CludoSearchInputFeature';

export interface SearchModalProps {
	show: boolean;
	onClose: () => void;
	searchHeader: string;
}

export const SearchModalFeature: React.FC<SearchModalProps> = ({ show, onClose, searchHeader }) => {
	const dictionary = useDictionaryContext();
	const siteContext = useSiteContext();
	const pageContext = usePageContext();

	const inputRef = useRef<HTMLInputElement>(null);

	const { url: privacyUrl, name: privacyName } = siteContext?.settings?.globalLinks?.privacyLink ?? {};

	useEffect(() => {
		if (!window.CludoSearch || !show || pageContext?.template === 'SearchPage') return;
		window.CludoSearch.isSearchResultPage = false;
		if (show) inputRef.current.focus();
	}, [show, pageContext]);

	return (
		<SearchModal
			id="searchModal"
			show={show}
			onClose={onClose}
			searchInput={<CludoSearchInputFeature ref={inputRef} ariaLabel="search-label" id="cludo-search-content-form" />}
			searchHeader={searchHeader}
			text={dictionary.getSearch('Disclaimer')}
			textLink={<LinkFeature url={privacyUrl} name={privacyName} target="_blank" />}
			ariaLabel={dictionary.getSearch('SearchModal')}
			ariaLabelClose={dictionary.getAction('Close')}
		/>
	);
};
