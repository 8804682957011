import classNames from 'classnames';
import * as React from 'react';
import { customizeElement } from 'utilities/customizeElement';
import styles from './Breadcrumb.module.scss';
export interface BreadcrumbProps {
	breadcrumbItems: React.ReactElement[];
	className?: string;
	label: string;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumbItems, className, label }) => (
	<div className={classNames(styles.Breadcrumb, className)}>
		<nav className={styles.Breadcrumb_nav}>
			<ol className={styles.Breadcrumb_list} aria-label={label}>
				{breadcrumbItems.map((link, index) => {
					const current = index === breadcrumbItems.length - 1;
					return (
						<li className={styles.Breadcrumb_item} key={index} aria-current={current ? 'page' : undefined}>
							{!current ? (
								<>{customizeElement({ element: link, style: styles.Breadcrumb_link })}</>
							) : (
								link.props.name ?? link.props.children
							)}
						</li>
					);
				})}
			</ol>
		</nav>
	</div>
);
