import classNames from 'classnames';
import React from 'react';
import styles from './CTALink.module.scss';

export interface CTALinkProps {
	className?: string;
	link?: UI.Link;
	label: string;
	type?: CTALinkType;
	color?: 'dark' | 'light' | 'theme';
	onClick?: (e: React.MouseEvent) => void;
}

export type CTALinkType = 'arrow' | 'logon';

export const CTALink: React.FC<CTALinkProps> = ({ className, link, label, type = 'arrow', color = 'dark', onClick }) => {
	return (
		<a
			className={classNames(className, styles.CTALink, styles[`CTALink___${type}`], styles[`CTALink___${color}`])}
			href={link.url}
			target={link.target}
			onClick={onClick}
		>
			{label}
		</a>
	);
};
