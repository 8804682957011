import classNames from 'classnames';
import styles from './Layout.module.scss';

export interface LayoutProps {
	themeClass: string;
	children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children, themeClass }) => {
	return <div className={classNames(styles.Layout, themeClass)}>{children}</div>;
};
