import { useSiteContext } from 'application/contexts/SiteContext';
import logger from 'designsystem/utilities/logger';
import { LayoutStyle } from 'features/Layouts/Layout/LayoutFeature';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { capitalizeFirstLetter } from 'utilities/string';
import { BreadcrumbFeature } from '../Breadcrumb';
import { LanguagePickerFeature } from '../LanguagePicker';
import { ReadSpeakerFeature } from '../ReadSpeaker';

const features = {
	ArticlePageFeature: dynamic(() => import('../../PageTypes/ArticlePage').then((module) => module.ArticlePageFeature)),
	CampaignPageFeature: dynamic(() => import('../../PageTypes/CampaignPage').then((module) => module.CampaignPageFeature)),
	CatalogPageFeature: dynamic(() => import('../../PageTypes/CatalogPage').then((module) => module.CatalogPageFeature)),
	ClosableHelpPageFeature: dynamic(() => import('../../PageTypes/ClosableHelpPage').then((module) => module.ClosableHelpPageFeature)),
	DocumentCatalogPageFeature: dynamic(() =>
		import('../../PageTypes/DocumentCatalogPage/DocumentCatalogPageFeature').then((module) => module.DocumentCatalogPageFeature),
	),
	DocumentCatalogPageAlternativeFeature: dynamic(() =>
		import('../../PageTypes/DocumentCatalogPage/DocumentCatalogPageAlternativeFeature').then(
			(module) => module.DocumentCatalogPageAlternativeFeature,
		),
	),
	DocumentPageFeature: dynamic(() => import('../../PageTypes/DocumentPage').then((module) => module.DocumentPageFeature)),
	HomePageFeature: dynamic(() => import('../../PageTypes/HomePage').then((module) => module.HomePageFeature)),
	JobCatalogPageFeature: dynamic(() => import('../../PageTypes/JobCatalogPage').then((module) => module.JobCatalogPageFeature)),
	NewsCatalogPageFeature: dynamic(() =>
		import('../../PageTypes/NewsCatalogPage/NewsCatalogPageFeature').then((module) => module.NewsCatalogPageFeature),
	),
	NewsCatalogPageAlternativeFeature: dynamic(() =>
		import('../../PageTypes/NewsCatalogPage/NewsCatalogPageAlternativeFeature').then((module) => module.NewsCatalogPageAlternativeFeature),
	),
	NewsPageFeature: dynamic(() => import('../../PageTypes/NewsPage').then((module) => module.NewsPageFeature)),
	PublicationCatalogPageFeature: dynamic(() =>
		import('../../PageTypes/PublicationCatalogPage/PublicationCatalogPageFeature').then((module) => module.PublicationCatalogPageFeature),
	),
	PublicationCatalogPageAlternativeFeature: dynamic(() =>
		import('../../PageTypes/PublicationCatalogPage/PublicationCatalogPageAlternativeFeature').then(
			(module) => module.PublicationCatalogPageAlternativeFeature,
		),
	),
	PublicationPageFeature: dynamic(() => import('../../PageTypes/PublicationPage').then((module) => module.PublicationPageFeature)),
	SearchPageFeature: dynamic(() => import('../../PageTypes/SearchPage').then((module) => module.SearchPageFeature)),
	TransportPageFeature: dynamic(() => import('../../PageTypes/TransportPage').then((module) => module.TransportPageFeature)),
	WebinarPageFeature: dynamic(() => import('../../PageTypes/WebinarPage').then((module) => module.WebinarPageFeature)),
};
export interface PageTypeFeatureProps extends Content.Page {
	additional?: JSX.Element;
}

export const PageTypeFeature: React.FC<PageTypeFeatureProps> = (page, { excludeBreadcrumb }) => {
	const { properties, pageLanguageVersions } = page ?? {};
	const { bannerContent, langPickerHide, hideAdditionalTopArea } = properties ?? {};

	const siteContext = useSiteContext();
	const router = useRouter();
	const layout = (router?.query?.layout as LayoutStyle) ?? null;

	const production = process.env.NODE_ENV === 'production';
	const pageName = `${capitalizeFirstLetter(page.template)}`;
	const featureName = `${pageName}Feature`;
	const { languagePicker } = siteContext?.settings ?? {};
	const showLanguagePicker = bannerContent?.length > 0 && languagePicker?.active && !langPickerHide && pageLanguageVersions?.length > 1;

	const additionalTopFeatures = {
		top:
			layout !== 'simple' && layout !== 'simpleNoClose' && !hideAdditionalTopArea ? (
				!excludeBreadcrumb ? (
					<>
						<BreadcrumbFeature /> <ReadSpeakerFeature />
					</>
				) : (
					<ReadSpeakerFeature />
				)
			) : null,
		bottom: showLanguagePicker ? <LanguagePickerFeature /> : false,
	};
	const additionalPageContentFeatures =
		bannerContent?.length < 1 && languagePicker?.active && !langPickerHide ? <LanguagePickerFeature padded /> : false;

	const PageTypeTemplate = features[featureName];

	if (!PageTypeTemplate) {
		const message = `PageType [${page.template}] is missing a React Component. Add missing export '${featureName}' from /features`;
		logger.error(message);
		if (!production) {
			return <div>{message}</div>;
		} else {
			return null;
		}
	}

	return <PageTypeTemplate {...page} additionalTop={additionalTopFeatures} additionalPageContent={additionalPageContentFeatures} />;
};
