import { Heading } from 'components/1-atoms/Heading';
import { Modal } from '../Modal';
import styles from './SearchModal.module.scss';

export interface SearchModalProps {
	show: boolean;
	searchHeader: string;
	searchInput: React.ReactElement;
	text?: string;
	textLink?: React.ReactElement;
	onClose: () => void;
	ariaLabel: string;
	ariaLabelClose: string;
	id?: string;
}

export const SearchModal: React.FC<SearchModalProps> = ({
	show,
	searchHeader,
	searchInput,
	text,
	textLink,
	onClose,
	ariaLabel,
	ariaLabelClose,
	id,
}: React.PropsWithChildren<SearchModalProps>) => {
	return (
		<Modal show={show} onClose={onClose} ariaLabel={ariaLabel} ariaLabelClose={ariaLabelClose} id={id} style="search">
			<div className={styles.SearchModal}>
				<Heading className={styles.SearchModal_header} headingLevel="h2" style="sm" id="search-label">
					{searchHeader}
				</Heading>
				{searchInput}
				{text && (
					<div className={styles.SearchModal_text}>
						<p>
							{text} {textLink}
						</p>
					</div>
				)}
			</div>
		</Modal>
	);
};
