import { usePageContext } from 'application/contexts/PageContext';
import { Link } from 'designsystem/components/1-atoms/Links';
import { Paragraph } from 'designsystem/components/1-atoms/Paragraph';
import { LanguageSelector, LanguageSelectorItem } from 'designsystem/components/2-molecules/LanguageSelector';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { getLanguageFromLocale } from './getLanguageFromLocale';

export const LanguagePickerFeature = ({ padded = true }: { padded?: boolean }): JSX.Element => {
	const pageContext = usePageContext();
	const router = useRouter();
	const [menuItems, setMenuItems] = useState(null);

	const currentLocale = router?.locale?.toLowerCase();

	useEffect(() => {
		const sortedPageLanguageVersions = pageContext?.pageLanguageVersions?.sort((a, b) => {
			const cultureA = a.cultureName.toLowerCase();
			const cultureB = b.cultureName.toLowerCase();
			let comparison = 0;
			if (currentLocale === cultureA && currentLocale !== cultureB) comparison = -1;
			if (currentLocale === cultureB && currentLocale !== cultureA) comparison = 1;
			return comparison;
		});

		setMenuItems(sortedPageLanguageVersions);
	}, [currentLocale, pageContext]);

	return (
		menuItems?.length > 1 && (
			<LanguageSelector
				listLinkItems={menuItems?.map((langObj, index) => {
					const locale = langObj?.cultureName?.toLowerCase();
					const language = getLanguageFromLocale(locale);
					return (
						<LanguageSelectorItem selected={index === 0} key={langObj?.cultureName}>
							{currentLocale === locale ? <Paragraph text={language} weight="bold" /> : <Link url={langObj?.pageUrl}>{language}</Link>}
						</LanguageSelectorItem>
					);
				})}
				padded={padded}
			/>
		)
	);
};
