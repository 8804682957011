import classNames from 'classnames';
import styles from './Main.module.scss';

export interface MainProps {
	children?: React.ReactNode;
	className?: string;
}

export const Main: React.FC<MainProps> = ({ children, className }) => (
	<main id="main" className={classNames(styles.Main, className)}>
		{children}
	</main>
);
