import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { usePageContext } from 'application/contexts/PageContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { NavigationItemProps } from 'designsystem/components/3-organisms/Navigation/NavigationItem';
import { Header } from 'designsystem/components/4-habitats/Header';
import { HeaderSimple } from 'designsystem/components/4-habitats/HeaderSimple';
import { HeaderTop } from 'designsystem/components/4-habitats/HeaderTop';
import { LinkFeature } from 'features/Modules/Link';
import { LoginFeature } from 'features/Modules/Login';
import { LogoFeature } from 'features/Modules/Logo';
import { SearchModalFeature } from 'features/Modules/SearchModal/SearchModalFeature';
import getConfig from 'next/config';
import React, { useState } from 'react';
import { LayoutStyle } from '../Layout/LayoutFeature';

export interface HeaderFeatureProps {
	navigationItems: Content.NavigationItem[];
	layout?: LayoutStyle;
}

export const HeaderFeature = ({ navigationItems, layout }: HeaderFeatureProps) => {
	const siteContext = useSiteContext();
	const pageContext = usePageContext();
	const dictionary = useDictionaryContext();
	const { publicRuntimeConfig } = getConfig();

	const { CONTENT_API_URL: cmsUrl, APP_ENV: appEnv } = publicRuntimeConfig ?? {};

	const [showSearch, setShowSearch] = useState(false);

	const [modalTrigger, setModalTrigger] = useState<HTMLElement>();
	const extraBurgerLinks: NavigationItemProps[] = siteContext?.settings?.globalLinks?.additionalBurgerLinks.map((extraBurgerLink) => {
		const itemIsCurrentPage = pageContext?.url === extraBurgerLink?.url;
		return {
			...extraBurgerLink,
			link: <LinkFeature url={extraBurgerLink.url} name={extraBurgerLink.name} currentPage={itemIsCurrentPage} />,
		};
	});

	const { searchHeader, searchEnabled } = siteContext?.settings?.searchConfiguration ?? {};

	const openSearchModal = (e: React.MouseEvent) => {
		if (!(e.target instanceof HTMLElement)) return;

		setModalTrigger(e.target);
		setShowSearch(true);
		e.preventDefault();
	};

	const closeSearchModal = () => {
		setShowSearch(false);
		setTimeout(() => modalTrigger?.focus(), 0);
	};
	return pageContext.template === 'ClosableHelpPage' || layout === 'simple' || layout === 'simpleNoClose' ? (
		<HeaderSimple closeText={layout !== 'simpleNoClose' ? dictionary.getAction('Close') : null} logo={<LogoFeature />} />
	) : (
		<Header
			topArea={appEnv === 'dev' || appEnv === 'test' ? <HeaderTop>{appEnv.toUpperCase() + ' ' + cmsUrl.replace(/\D/g, '')}</HeaderTop> : null}
			heading={dictionary.getExplainer(siteContext?.theme)}
			logo={<LogoFeature />}
			darkLogo={<LogoFeature dark />}
			enforcedFullLogo={<LogoFeature dark enforceFullLogo />}
			navItems={
				navigationItems?.length > 0
					? navigationItems.map((item) => {
							const { link, title, subLinks } = item?.content?.properties ?? {};
							const itemIsActive = pageContext?.url.startsWith(link?.url);
							const itemIsCurrentPage = pageContext?.url === link?.url;
							const { name, url, target } = link ?? {};
							return {
								target: link?.target,
								name: title ? title : name,
								link: <LinkFeature url={url} name={name} target={target} currentPage={itemIsCurrentPage} />,
								current: itemIsActive,
								descendants:
									subLinks?.length > 0
										? subLinks?.map((link) => {
												const itemIsActive = pageContext.url === link.url;
												return {
													name: link.name,
													url: link?.url,
													link: <LinkFeature url={link.url} name={link.name} currentPage={itemIsActive} />,
													current: itemIsActive,
												};
										  })
										: null,
							};
					  })
					: []
			}
			extraBurgerLinks={extraBurgerLinks}
			burgerButtonText={dictionary.getLabel('Menu')}
			goToMainText={dictionary.getAction('GoToContent')}
			searchButtonOnClick={searchEnabled && openSearchModal}
			searchButtonText={dictionary.getSearch('Search')}
			skipToMainText={dictionary.getServiceInfo('SkipToMain')}
			openText={dictionary.getAction('Open')}
			closeText={dictionary.getAction('Close')}
			authentication={<LoginFeature />}
		>
			<SearchModalFeature searchHeader={searchHeader} show={showSearch} onClose={closeSearchModal} />
		</Header>
	);
};
