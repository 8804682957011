import { Heading } from 'components/1-atoms/Heading';
import { customizeElement } from 'utilities/customizeElement';
import styles from './TransportLink.module.scss';
export interface TransportLinkProps {
	link: React.ReactElement;
	title: string;
	text: string;
	highlighted: boolean;
}

export const TransportLink: React.FC<TransportLinkProps> = ({ link, title, text, highlighted }) => {
	const styling = highlighted === true ? styles.TransportLink + ' ' + styles.TransportLink___highlighted : styles.TransportLink;
	return customizeElement({
		element: link,
		style: styling,
		children: (
			<>
				<Heading headingLevel="h2" style="md" className={styles.TransportLink_heading}>
					{title}
				</Heading>
				<p className={styles.TransportLink_text}>{text}</p>
			</>
		),
	});
};
