import classNames from 'classnames';
import styles from './Paragraph.module.scss';
export interface ParagraphProps {
	className?: string;
	text: string;
	style?: 'muted' | 'suppressed';
	alignment?: 'center';
	size?: 'small';
	weight?: 'bold';
}

export const Paragraph: React.FC<ParagraphProps> = ({ className, text, alignment, style, size, weight }) => {
	return (
		<p
			className={classNames(
				styles.Paragraph,
				styles[`Paragraph___${alignment}`],
				styles[`Paragraph___${style}`],
				styles[`Paragraph___${size}`],
				styles[`Paragraph___${weight}`],
				className,
			)}
		>
			{text}
		</p>
	);
};
