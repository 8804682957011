import { fireAdobeEvent } from 'helpers/adobeEvent';
import { getAbsoluteUrl } from 'helpers/urlHandlers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleAdobeLinkClick = (targetElement: any, pageName: string) => {
	const formatElement = targetElement.parentElement;
	if (formatElement.classList.contains('RichText_buttonLink')) {
		return fireAdobeEvent('greater_than_btn', {
			buttonName: targetElement.innerText,
			href: getAbsoluteUrl(targetElement.href),
			pageName: pageName,
		});
	}
	if (formatElement.classList.contains('RichText_arrowLink')) {
		return fireAdobeEvent('arrow_link', { buttonName: targetElement.innerText, pageName: pageName });
	}
	if (formatElement.classList.contains('RichText_login')) {
		return fireAdobeEvent('cta_clicks', { buttonName: targetElement.innerText, pageName: pageName, href: getAbsoluteUrl(targetElement.href) });
	}
	if (formatElement.dataset.trackingId === 'legal_click') {
		return fireAdobeEvent('legal_click', { pageName });
	}
	const collapseElement = formatElement.closest('[data-tracking-id=collapse]');
	if (collapseElement) {
		return fireAdobeEvent('accordion_link_click', {
			name: collapseElement.firstElementChild.innerText,
			type: 'text-with-link',
			href: targetElement.href,
			text: targetElement.innerText,
		});
	}
	if (targetElement.id === 'sw-supchatwidget-btn') {
		return fireAdobeEvent('chat_open', { type: 'chatbot' });
	}
	const chatcollapse = targetElement.closest('#chatcollapse');
	if (chatcollapse) {
		if (targetElement === chatcollapse.querySelector(':scope > div > div > form.visitor-form > button')) {
			return fireAdobeEvent('chat_start', { type: 'chatbot' });
		} else if (targetElement === chatcollapse.querySelector(':scope #closeChatConfirmation button[type=submit]')) {
			return fireAdobeEvent('chat_complete', { type: 'chatbot' });
		}
	}
	const footer = targetElement.closest('Footer');
	if (footer) {
		return fireAdobeEvent('footer_link', { buttonName: targetElement.innerText, pageName: pageName, href: getAbsoluteUrl(targetElement.href) });
	}
	const siblingNavigationElement = formatElement.closest('[data-tracking-id=siblingNavigation]');
	if (siblingNavigationElement) {
		return fireAdobeEvent('sister_click', { sisterName: targetElement.innerText, pageName: pageName, href: targetElement.href });
	}
};
