import classNames from 'classnames';
import styles from './FooterSection.module.scss';

export interface FooterSectionProps {
	style?: 'identity';
	center?: boolean;
	children?: React.ReactNode;
}

export const FooterSection: React.FC<FooterSectionProps> = ({ children, style, center }) => (
	<div className={classNames(styles.FooterSection, styles[`FooterSection___${style}`], center ? styles.FooterSection___center : '')}>
		<div className={styles.FooterSection_wrapper}>{children}</div>
	</div>
);
