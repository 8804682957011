import classNames from 'classnames';
import { Heading } from 'components/1-atoms/Heading';
import styles from './Footer.module.scss';
import { FooterSection } from './FooterSection';

export interface FooterProps {
	children: React.ReactNode;
	className?: string;
	logoarea?: React.ReactNode;
	heading?: string;
	layout?: UI.Layout;
}

export const Footer: React.FC<FooterProps> = ({ className, logoarea, heading, children, layout }) => {
	return (
		<footer className={classNames(styles.Footer, className)}>
			{layout !== 'simple' && heading ? (
				<FooterSection style="identity">
					<div className={styles.Footer_logo}>{logoarea}</div>
					<Heading headingLevel="h2" style="md" className={styles.Footer_heading}>
						{heading}
					</Heading>
				</FooterSection>
			) : null}
			{children}
		</footer>
	);
};
