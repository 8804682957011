import { IconButton } from 'components/1-atoms/Buttons';
import LogoutIcon from 'icons/icon-log-out.svg';
import styles from './Logout.module.scss';

export interface LogoutProps {
	logoutText: string;
	callBack?: () => void;
}
export const Logout = ({ logoutText, callBack }: LogoutProps) => {
	return (
		<div className={styles.Logout}>
			<IconButton className={styles.Logout_button} iconSize={'auto'} icon={<LogoutIcon />} label={logoutText} onClick={() => callBack()} />
		</div>
	);
};
