export const getLanguageFromLocale = (locale: string) => {
	switch (locale) {
		case 'da-dk':
			return 'Dansk';
		case 'en-us':
			return 'English';
		case 'de-de':
			return 'Deutsch';
		case 'lt':
			return 'Lietuvių';
		case 'pl':
			return 'Polski';
		case 'ro':
			return 'Română';
		case 'es':
			return 'Español';
		case 'uk':
			return 'Українська';
		case 'kl':
			return 'Kalaallisut';
	}
};
