import { fireAdobeEvent } from 'helpers/adobeEvent';

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		rsConf: any;
	}
}
export const setReadSpeakerConfig = () => {
	window.rsConf = {
		general: { skipHiddenContent: true },
		settings: { hlword: 'underline', hlsent: '#72728a', hltext: '#ffffff' },
		ui: { tools: { settings: false, textmode: false, pageMask: false, download: false, dictionary: false, translate: false } },
		cb: {
			ui: {
				open: () => {
					const mainContent: HTMLElement = document.querySelector('#main');
					mainContent.dataset.readspeaker = 'active';
					const expandableElements = mainContent.querySelectorAll('.collapse[aria-hidden="true"]');
					expandableElements?.length &&
						expandableElements.forEach((elm) => {
							if (elm instanceof HTMLElement) {
								elm.setAttribute('aria-hidden', 'false');
								elm.classList.add('show');
								elm.setAttribute('data-web-reader', '');
								document.querySelector('[aria-controls="' + elm.id + '"]')?.setAttribute('aria-expanded', 'true');
							}
						});
					fireAdobeEvent('read_out_loud', { pageName: window?.statx?.digitalData?.pageInfo?.pageName });
					window?.rspkr?.ui?.addClickEvents();
				},
				beforeclose: () => {
					const mainContent: HTMLElement = document.querySelector('#main');
					delete mainContent.dataset.readspeaker;
					const expandedElements = mainContent.querySelectorAll('[data-web-reader]');
					expandedElements?.length &&
						expandedElements.forEach((elm) => {
							if (elm instanceof HTMLElement) {
								elm.setAttribute('aria-hidden', 'true');
								elm.classList.remove('show');
								elm.removeAttribute('data-web-reader');
								document.querySelector('[aria-controls="' + elm.id + '"]')?.setAttribute('aria-expanded', 'false');
							}
						});
				},
			},
		},
	};
};
