import { Button } from 'components/1-atoms/Buttons';
import CloseIcon from 'icons/icon_close_cross.svg';
import styles from './HeaderSimple.module.scss';

export interface HeaderSimpleProps {
	closeText?: string;
	logo?: React.ReactNode;
}
export const HeaderSimple = ({ closeText, logo }: HeaderSimpleProps) => {
	const handleClick = () => {
		window.close();
	};

	return (
		<header className={styles.HeaderSimple}>
			<div className={styles.HeaderSimple_wrapper}>
				<div className={styles.HeaderSimple_logo}>{logo}</div>

				{closeText && (
					<Button className={styles.HeaderSimple_closeButton} onClick={handleClick} aria-label={closeText}>
						<span className={styles.HeaderSimple_closeButtonContent} aria-hidden={true}>
							<CloseIcon className={styles.HeaderSimple_closeIcon} />
							<span className={styles.HeaderSimple_closeText}>{closeText}</span>
						</span>
					</Button>
				)}
			</div>
		</header>
	);
};
