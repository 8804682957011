export const getRSCustomerId = (theme: Content.Theme) => {
	switch (theme) {
		case 'ADST':
			return 14026;
		case 'GAELDST':
			return 14025;
		case 'MOTORST':
			return 14024;
		case 'SKAT':
			return 8176;
		case 'SANST':
			return 14022;
		case 'SKTST':
			return 14021;
		case 'TOLDST':
			return 14020;
		case 'UFST':
			return 14019;
		case 'VURDST':
			return 14018;
		case 'WEBGUIDE':
			return 14019;
	}
};
