import classNames from 'classnames';
import { Heading, HeadingLevel, HeadingStyle } from 'components/1-atoms/Heading';
import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { generateId } from 'utilities/id';
import useTransition from '../../../hooks/useTransition';
import styles from './Collapse.module.scss';

export interface CollapseProps {
	className?: string;
	header?: string;
	headingLevel?: HeadingLevel;
	headingStyle?: HeadingStyle;
	anchor?: string;
	id?: string;
	isOpen?: boolean;
	minHeight?: number;
	style?: 'noBorder';
	buttonStyle?: 'minimal';
	isSubCollapse?: boolean;
	appendHeading?: string;
	handleClick?: (event?: React.MouseEvent<HTMLElement>) => void;
	children?: React.ReactNode;
	collapseHeadingDetail?: {
		className?: string;
		headingLevel?: HeadingLevel;
		headingStyle?: HeadingStyle;
		headingLeft?: string;
		headingRight?: string | number;
		collapse?: CollapseProps;
	};
	noBorder?: boolean;
}

export const Collapse: React.FC<CollapseProps> = ({
	className,
	header,
	headingLevel = 'h2',
	headingStyle,
	anchor,
	id = null,
	isOpen = false,
	minHeight,
	style,
	buttonStyle,
	isSubCollapse = false,
	appendHeading,
	handleClick,
	collapseHeadingDetail,
	children,
	noBorder = false,
	...props
}: React.PropsWithChildren<CollapseProps>) => {
	const collapseBody = useRef<HTMLDivElement>(null);
	const transition = useTransition(collapseBody, minHeight);

	const [collapseIsOpen, setCollapseIsOpen] = useState(isOpen);
	const [syncId, setSyncId] = useState(id);
	useEffect(() => {
		setCollapseIsOpen(isOpen);
	}, [isOpen]);

	useEffect(() => {
		!id && setSyncId(generateId());
	}, [header, children, id]);

	const getHeadingStyle = (headingLevel: string) => {
		switch (headingLevel) {
			case 'h2':
				return 'md';
			case 'h3':
				return 'sm';
			case 'h4':
				return 'xs';
			default:
				return;
		}
	};

	return (
		<div
			className={classNames(
				'collapser',
				styles.Collapse,
				styles[`Collapse___${style}`],
				className,
				isSubCollapse ? styles.Collapse___subCollapse : '',
			)}
			{...props} // used for adobe tracking
		>
			{(collapseHeadingDetail?.headingLeft || collapseHeadingDetail?.headingRight) && (
				<div className={styles.Collapse_detailedHeader}>
					<Heading className={styles.Collapse_detailedHeading} headingLevel={collapseHeadingDetail.headingLevel}>
						{collapseHeadingDetail.headingLeft && collapseHeadingDetail.headingLeft}
					</Heading>
					<p className={classNames(styles.Collapse_detailedHeading, styles.Collapse_detailedHeading___right)}>
						{collapseHeadingDetail.headingRight && collapseHeadingDetail.headingRight}
					</p>
				</div>
			)}

			{header && (
				<Heading headingLevel={headingLevel} style={headingStyle ?? getHeadingStyle(headingLevel)}>
					<button
						className={classNames(
							'collapser__header',
							`collapser__header___${style}`,
							styles.Collapse_heading,
							styles[`Collapse_heading___${buttonStyle}`],
						)}
						type="button"
						aria-expanded={collapseIsOpen}
						aria-controls={syncId}
						id={anchor}
						onClick={handleClick ? handleClick : () => setCollapseIsOpen(!collapseIsOpen)}
					>
						{header}
						{appendHeading && <p className={styles.Collapse_appendHeading}>{appendHeading}</p>}
					</button>
				</Heading>
			)}
			<CSSTransition in={collapseIsOpen} timeout={300} {...transition} nodeRef={collapseBody}>
				<div
					id={syncId}
					className={classNames(`collapse${collapseIsOpen ? ' show' : ''}`, styles.Collapse_container)}
					aria-hidden={minHeight ? false : !collapseIsOpen}
					ref={collapseBody}
				>
					{children && (
						<div
							ref={collapseBody}
							className={classNames('collapser__body', styles.Collapse_body, noBorder && styles.Collapse_body___noBorder)}
						>
							{children}
						</div>
					)}
				</div>
			</CSSTransition>
		</div>
	);
};
