import { PageContextProps } from 'application/contexts/PageContext';
import { AdobeEvent, fireAdobeEvent } from 'helpers/adobeEvent';
import Script from 'next/script';
import { useCallback, useEffect, useState } from 'react';
import { handleAdobeLinkClick } from './handleAdobeLinkClick';
import { Statx } from './statx';

/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
	interface Window {
		_satellite: {
			track: (eventName: string, eventValue?: any) => void;
			_container: any;
		};
		statx: Statx;
	}
}
export interface AdobeTrackingProps {
	defaultActive: boolean;
	pageContext: Partial<PageContextProps>;
	siteContext: Partial<Models.Site>;
	language: string;
	documentType: string;
	environment: 'dev' | 'test' | 'prod';
	theme: Content.Theme;
	currentUrl: string;
	previousUrl: string;
}

export const AdobeTracking = ({
	defaultActive,
	pageContext,
	siteContext,
	language,
	documentType,
	environment,
	theme,
	currentUrl,
	previousUrl,
}: AdobeTrackingProps) => {
	const { url, content, name: pageName, segment } = pageContext ?? {};
	const currentSegment = segment
		?.map((segmentItem) => segmentItem?.trackingName)
		?.sort()
		?.join('_');

	const [scriptLoaded, setScriptLoaded] = useState(defaultActive);

	const urlPathname = url === '#' ? 'preview' : !url ? 'error' : new URL(currentUrl).pathname;
	const urlPathSections = urlPathname.split('/');
	urlPathSections.shift();
	const totalSections = urlPathSections.length;
	const sectionLengthMax5 = totalSections > 5 ? 5 : totalSections;
	const urlPathSectionsLimited = urlPathSections.slice(0, sectionLengthMax5);

	const getPageSections = (position: number): string => {
		const siteSections = urlPathSectionsLimited.slice(0, position);
		const sortedSiteSections = String(siteSections).replaceAll(',', ':');
		const sanitizedSections = sortedSiteSections.endsWith(':') ? sortedSiteSections.slice(0, sortedSiteSections.length - 1) : sortedSiteSections;

		return checkLength(sanitizedSections);
	};
	const checkLength = (item: string) => {
		if (item?.length > 255) {
			item = item.substring(0, 255 - 3) + '...';
		}
		return item;
	};
	const urlPath = urlPathname.length > 1 ? urlPathname.slice(1).replaceAll('/', ':') : currentSegment ? currentSegment : 'forside';
	const sanitizedPath = urlPath.endsWith(':') ? urlPath.slice(0, urlPath.length - 1) : urlPath;
	const pageIsHomePage = siteContext?.settings?.homePageRedirectUrl === url;
	const getStatx = (): Window['statx'] => {
		const statx: Window['statx'] = {
			trc: true, // Set this for trace mode
			timeoutMax: 20,
			timeoutMilliseconds: 500,
			digitalData: {
				pageInfo: {
					buildVersion: window._satellite && window._satellite._container.buildInfo.buildDate,
					pageName: checkLength(pageName !== '404' ? sanitizedPath : `404:${currentUrl}`),
					language: language?.split('-')[0],
					id: pageContext.id,
					oid: pageContext.content.referenceOId ? pageContext.content.referenceOId : 'ikke defineret',
					profile: currentSegment ? currentSegment : 'ikke defineret',
					referrer: previousUrl,
					subject: content?.subject?.name,
					contentType: pageName !== '404' ? (pageIsHomePage ? 'homePage' : documentType) : 'error page',
					department: theme,
					domain: pageContext.host,
					pageNameNoSection: pageName !== '404' ? pageName : 'error',
					siteSection1: pageName !== '404' ? (getPageSections(1) ? 'forside:' + getPageSections(1) : 'forside') : 'error',
					siteSection2: pageName !== '404' ? (getPageSections(2) ? 'forside:' + getPageSections(2) : 'forside') : 'error',
					siteSection3: pageName !== '404' ? (getPageSections(3) ? 'forside:' + getPageSections(3) : 'forside') : 'error',
					siteSection4: pageName !== '404' ? (getPageSections(4) ? 'forside:' + getPageSections(4) : 'forside') : 'error',
					siteSection5: pageName !== '404' ? (getPageSections(5) ? 'forside:' + getPageSections(5) : 'forside') : 'error',
					siteSectionLevel: totalSections,
				},
				search: {
					filterName: null,
					term: null,
					results: null,
					page: null,
				},
			},
			maxLength: 255,
			isDocument: documentType == 'transportPage' ? '0' : '1',
			fireEvent: function (name: string, o) {
				let cnt = 0;
				(function dowait() {
					if (
						typeof window._satellite === 'undefined' ||
						!window._satellite ||
						!window._satellite._container ||
						!window._satellite._container.buildInfo ||
						!window._satellite._container.buildInfo.buildDate
					) {
						cnt += 1;
						if (cnt < statx.timeoutMax) {
							if (statx.trc) console.warn('fireWait dowait #' + cnt, statx.timeoutMilliseconds);
							setTimeout(dowait, statx.timeoutMilliseconds);
						}
					} else {
						if (statx.trc) console.log('event track: ' + name, o, 'fireWait dowait ready on counter: ' + cnt);
						if (typeof o !== 'undefined') {
							window._satellite && window._satellite.track(name, o);
						} else {
							window._satellite && window._satellite.track(name);
						}
					}
				})();
			},
			cludo: function (cludoSearch) {
				const { params, totalDocuments, lastQuery } = cludoSearch ?? {};

				let eventName: AdobeEvent = 'ajaxPageView';

				statx.digitalData.search = {
					filterName: 'ingen kategori valgt',
					page: cludoSearch?.params?.page,
					results: totalDocuments > 0 ? totalDocuments : 'zero',
					term: lastQuery,
				};
				// Only 1 filter is applicable
				if (params?.filters[0]) {
					statx.digitalData.search.filterName = params?.filters[0];
					eventName = 'int_search_filter_selection';
				}

				if (statx.trc) console.info('cludo search init keys: ' + statx.digitalData.search);

				fireAdobeEvent(eventName, statx.digitalData);
			},
		};

		return statx;
	};

	const handleClick = useCallback(
		(event) => {
			const targetElement = event.target;
			if (targetElement.tagName.toLowerCase() === 'a') return handleAdobeLinkClick(targetElement, pageName);
			if (targetElement?.parentElement.tagName.toLowerCase() === 'a') return handleAdobeLinkClick(targetElement.parentElement, pageName);
		},
		[pageName],
	);

	useEffect(() => {
		if (url === '#' || !scriptLoaded) return;
		const statx = getStatx();
		if (!statx) return;
		window.statx = statx;
		fireAdobeEvent('pageView', statx.digitalData);

		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scriptLoaded, url, handleClick]);

	const prodScript = '/js/adobe/prod/launch-daccdf9604b8.min.js';
	const testScript = '/js/adobe/test/launch-10a1f2f46df1-development.min.js';

	return <Script src={`${environment === 'prod' ? prodScript : testScript}`} onLoad={() => setScriptLoaded(true)} />;
};
