export type CludoLang = 'da' | 'en' | 'de';

export type EngineId =
	| '13369'
	| '13460'
	| '13130'
	| '13212'
	| '13213'
	| '13214'
	| '13215'
	| '13502'
	| '13217'
	| '13218'
	| '13219'
	| '13220'
	| '13221'
	| '13222'
	| '13224'
	| '13225'
	| '13226'
	| '13227'
	| '13228'
	| '13229'
	| '13230'
	| '13459'
	| '13514';

export const getCludoEngineId = (theme: Content.Theme, lang?: CludoLang): EngineId => {
	switch (theme) {
		case 'SKAT':
			if (lang === 'da') return '13369';
			if (lang === 'en') return '13460';
			if (lang === 'de') return '13213';
			return '13514'; // umbrella engine for multiple languages
		case 'TOLDST':
			if (lang === 'da') return '13130';
			if (lang === 'en') return '13212';
			if (lang === 'de') return '13213';
			return '13130';
		case 'MOTORST':
			if (lang === 'da') return '13214';
			if (lang === 'en') return '13215';
			if (lang === 'de') return '13502';
			return '13214';
		case 'VURDST':
			if (lang === 'da') return '13217';
			if (lang === 'en') return '13218';
			if (lang === 'de') return '13219';
			return '13217';
		case 'GAELDST':
			if (lang === 'da') return '13220';
			if (lang === 'en') return '13221';
			if (lang === 'de') return '13222';
			return '13220';
		case 'SANST':
			return '13224';
		case 'SKM':
			return '13225';
		case 'SKTST':
			return '13226';
		case 'SKTFV':
			return '13227';
		case 'ADST':
			return '13228';
		case 'UFST':
			return '13230';
		case 'ITTI':
			return '13459';
		case 'WEBGUIDE':
			return '13230';
	}
};
