import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { RefObject, forwardRef, useEffect, useState } from 'react';

export interface CludoSearchInputProps {
	id: 'cludo-search-content-form' | 'cludo-search-content-form2';
	ariaLabel?: string;
}

export const CludoSearchInputFeature = forwardRef(function CludoSearchInputFeature(
	{ id, ariaLabel }: CludoSearchInputProps,
	ref: RefObject<HTMLInputElement>,
) {
	const dictionary = useDictionaryContext();
	const [inputValue, setInputValue] = useState('');

	useEffect(() => {
		if (!window.CludoSearch) return;
		window.CludoSearch.params.query = inputValue;
	}, [inputValue]);
	return (
		<div id={id}>
			<div className="input-group" id="siteSearchWrapperInline">
				<label className="sr-only" htmlFor="siteSearchInline">
					{dictionary.getSearch('Placeholder')}
				</label>
				<input
					ref={ref}
					aria-labelledby={ariaLabel}
					type="search"
					id="siteSearchInline"
					className="form-control"
					placeholder={dictionary.getSearch('Placeholder')}
					autoComplete="off"
					onChange={(e) => setInputValue(e.target.value)}
					value={inputValue}
				/>
				<span className="input-group-btn">
					<button
						id="siteSearchButtonInline"
						className="btn skts-icon skts-icon--soeg-hvid skts-icon--large"
						type="button"
						data-stat="cludo search"
						onClick={() => setInputValue(inputValue)}
					>
						<span className="input-group-btn">
							<span className="sr-only">{dictionary.getSearch('Search')}</span>
						</span>
					</button>
				</span>
			</div>
		</div>
	);
});
