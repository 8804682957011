import classNames from 'classnames';
import styles from './LanguageSelector.module.scss';
import { LanguageSelectorItem } from './LanguageSelectorItem/LanguageSelectorItem';
export interface LanguageSelectorProps {
	listLinkItems: React.ReactNode;
	padded?: boolean;
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({ listLinkItems, padded }) => {
	return (
		<div className={classNames(styles.LanguageSelector, padded && styles.LanguageSelector___padded)}>
			<div className={styles.LanguageSelector_wrapper}>
				<ul className={styles.LanguageSelector_list}>
					<LanguageSelectorItem globe />
					{listLinkItems}
				</ul>
			</div>
		</div>
	);
};
