import React from 'react';

export const ExcludedFromCludo = ({ children }: { children: React.ReactNode }) => {
	return (
		<>
			<script dangerouslySetInnerHTML={{ __html: `<!--cludooff: index-->` }} />
			{children}
			<script dangerouslySetInnerHTML={{ __html: `<!--cludoon: index-->` }} />
		</>
	);
};
