import classNames from 'classnames';
import { customizeElement } from 'utilities/customizeElement';
import styles from './NavigationItem.module.scss';

export interface NavigationItemProps {
	name: string;
	target?: string;
	link: React.ReactElement;
	descendants?: Array<NavigationItemProps>;
	current?: boolean;
	active?: boolean;
	hidden?: boolean;
	title?: string;
	description?: string;
	children?: React.ReactNode;
	style?: 'list' | 'header';
	collapsed?: boolean;
}

export const NavigationItem = ({
	name,
	title,
	link,
	current,
	active,
	children,
	style,
	collapsed,
	callback,
	className,
}: NavigationItemProps & { callback?: () => void; className?: string }) => {
	return (
		<li
			className={classNames(
				styles.NavigationItem,
				{
					[styles[`NavigationItem___${style}Active`]]: active,
					[styles[`NavigationItem___${style}Current`]]: current,
				},
				styles[`NavigationItem___${style}`],
				collapsed && styles.NavigationItem___open,
				className,
			)}
		>
			<span className={styles.NavigationItem_linkWrapper} onClick={callback}>
				{customizeElement({
					element: link,
					style: styles.NavigationItem_link,
					children: <span className={styles.NavigationItem_linkName}>{title && title !== '' ? title : name}</span>,
				})}
			</span>

			{children}
		</li>
	);
};
