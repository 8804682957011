import { EngineId } from './cludoHelpers';

/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
	interface Window {
		Cludo: any;
		CludoSearch: any;
	}
}
// CludoSettings explained here: https://help.cludo.com/how-to/what-does-the-cludojs-options-do/
export interface CludoSettings {
	customerId: number;
	engineId: EngineId;
	searchUrl: string;
	searchInputs: string[];
	initSearchBoxText: string;
	loading: string;
	language: string;
	facets: string[];
	customerTemplate: string;
	translateSearchTemplates: boolean;
	hideSearchFilters: boolean;
	jumpToTopOnFacetClick: boolean;
	timeoutMax: number;
	timeoutMilliseconds: number;
	customCallbackAfterSearch: () => void;
}

interface CludoConfigurationProps {
	searchPage: Content.Link;
	language: string;
	hideSearchFilters: boolean;
	engineId: string;
}

export const CludoInit: any = ({ searchPage, language, hideSearchFilters, engineId }: CludoConfigurationProps) => {
	const pageCountPicker = () => {
		const pagePicker = <HTMLSelectElement>document.getElementById('cludo_pagePicker');
		if (!pagePicker) return;
		pagePicker.addEventListener('change', () => {
			window.CludoSearch.params.perPage = pagePicker.value;
			window.CludoSearch.doSearch();
			window.scrollTo({ top: 0, behavior: 'smooth' });
		});
	};
	if (!window.Cludo || !searchPage) return;
	const cludoSettings: CludoSettings = {
		customerId: 2073,
		engineId: engineId as EngineId,
		searchUrl: searchPage?.url?.endsWith('/') ? searchPage?.url?.slice(0, -1) : searchPage?.url,
		searchInputs: ['cludo-search-content-form', 'cludo-search-content-form2'],
		initSearchBoxText: '',
		language,
		facets: ['Category'],
		customerTemplate: `Redesign2022 - ${language?.toUpperCase()}`,
		translateSearchTemplates: true,
		hideSearchFilters,
		jumpToTopOnFacetClick: false,
		loading: '<div class="spinner spinner--dark"></div>',
		timeoutMax: 20,
		timeoutMilliseconds: 500,
		customCallbackAfterSearch: function () {
			const searchModal = document.querySelector('[data-id=searchModal]');
			const closeButton: HTMLElement = searchModal && searchModal.querySelector('[data-close=searchModal]');
			closeButton && closeButton.click();
			window?.statx && window.statx.cludo(window.CludoSearch);
			pageCountPicker();
		},
	};
	const initCludoConfig = (index) => {
		const cludoConfig = new window.Cludo(cludoSettings);
		if (!cludoConfig && index < 20) initCludoConfig(index + 1);
		window.CludoSearch = cludoConfig;
		if (window.CludoSearch.translateProvider.translations[language]) {
			window.CludoSearch.translateProvider.translations[language]['in_category'] = ' ';
			window.CludoSearch.translateProvider.translations[language]['and'] = ' ';
		}
		window.CludoSearch.init();
	};

	initCludoConfig(1);
};
