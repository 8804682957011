import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { ExcludedFromCludo } from 'helpers/ExcludedFromCludo';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import ReadSpeakerIcon from 'ui/icons/volume.svg';
import { setReadSpeakerConfig } from './rsConf';
import { getRSCustomerId } from './rsCustomerId';
declare global {
	interface Window {
		rspkr: {
			ui: {
				destroyActivePlayer: () => void;
				addClickEvents: () => void;
			};
		};
	}
}
export const ReadSpeakerFeature = () => {
	const [customerId, setCustomerId] = useState(null);
	const siteContext = useSiteContext();
	const dictionary = useDictionaryContext();
	const router = useRouter();
	const urlPath = router.asPath;
	const { readSpeakerEnabled } = siteContext?.settings?.readSpeaker ?? {};

	useEffect(() => {
		setReadSpeakerConfig();
	}, []);

	useEffect(() => {
		const id = getRSCustomerId(siteContext?.theme);
		setCustomerId(id);
	}, [siteContext.theme]);

	useEffect(() => {
		window?.rspkr?.ui && window.rspkr.ui.destroyActivePlayer();
		window?.rspkr?.ui?.addClickEvents();
	}, [urlPath]);

	return readSpeakerEnabled && customerId ? (
		<div className="u-margin-left-auto u-print-none">
			<ExcludedFromCludo>
				<Script src={`//cdn-eu.readspeaker.com/script/${customerId}/webReader/webReader.js?pids=wr`} id="rs_req_Init" strategy="lazyOnload" />

				<div id="readspeaker_button1" className="rs_skip rsbtn rs_preserve">
					<a
						rel="nofollow"
						className="rsbtn_play"
						accessKey="L"
						title={dictionary.getServiceInfo('ListenToThisPageWithWebReader')}
						href={`https://app-eu.readspeaker.com/cgi-bin/rsent?customerid=${customerId}&lang=${router?.locale.replace(
							'-',
							'_',
						)}&readid=main`}
					>
						<span className="rsbtn_left rsimg rspart">
							<span className="rsbtn_text">
								<span className="readspeaker_icon">
									<ReadSpeakerIcon />
								</span>
								<span>{dictionary.getAction('ReadAloud')}</span>
							</span>
						</span>
						<span className="rsbtn_right rsimg rsplay rspart"></span>
					</a>
				</div>
			</ExcludedFromCludo>
		</div>
	) : null;
};
