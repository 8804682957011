import { Footer, FooterSection } from 'designsystem/components/4-habitats/Footer';
import { ModuleFeature } from 'features/Modules/DynamicModule';
import CrownLogo from 'ui/icons/logos/logo-crown.svg';
import { LayoutStyle } from '../Layout/LayoutFeature';

export interface FooterFeatureProps {
	footer: Content.PageContent['footer'];
	layout?: LayoutStyle;
}

export const FooterFeature = ({ footer, layout }: FooterFeatureProps) => {
	return (
		<Footer heading={footer?.heading} logoarea={<CrownLogo />} layout={layout === 'simpleNoClose' ? 'simple' : layout}>
			{footer.content
				? footer.content
						?.filter((item) => {
							if (layout !== 'simple' && layout !== 'simpleNoClose') return true;
							const { layoutEnable } = item?.settings?.properties ?? {};
							return layoutEnable === layout;
						})
						?.map((footerItem, index) => {
							return (
								<FooterSection key={index}>
									<ModuleFeature contentModule={footerItem} />
								</FooterSection>
							);
						})
				: null}
		</Footer>
	);
};
