import classNames from 'classnames';
import CrownLogo from 'icons/logos/logo-crown.svg';
import React from 'react';

import { Icon } from 'components/1-atoms/Media';
import styles from './Logo.module.scss';

export interface LogoProps {
	className?: string;
	heading?: string;
	linkText?: string;
	tabIndex?: number;
	enforceFullLogo?: boolean;
	url?: string;
	logo?: React.ReactNode;
}

export const Logo: React.FC<LogoProps> = React.forwardRef(function Logo(
	{ className, heading = 'Skat', linkText, tabIndex, enforceFullLogo = false, url = '/', logo, ...linkProps },
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	ref,
) {
	return (
		<a className={classNames(styles.Logo, className)} href={url} title={linkText} tabIndex={tabIndex} {...linkProps}>
			<p id="logoDescription" className={styles.Logo_heading}>
				{heading}
			</p>

			<Icon className={classNames(styles.Logo_asset, styles.Logo_asset___desktop)} size="auto" aria-labelledby="logoDescription">
				{logo}
			</Icon>
			<Icon
				className={classNames(styles.Logo_asset, styles.Logo_asset___mobile, { [styles.Logo_asset___enforceFullLogo]: enforceFullLogo })}
				aria-labelledby="logoDescription"
			>
				{enforceFullLogo ? logo : <CrownLogo />}
			</Icon>
		</a>
	);
});
