import { usePageContext } from 'application/contexts/PageContext';
import { CTALink, CTALinkType, Link, LinkProps } from 'designsystem/components/1-atoms/Links';
import getConfig from 'next/config';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

export interface LinkComponent {
	url: string;
	name?: string;
	target?: string;
	ariaLabel?: string;
	asText?: boolean;
	isCTALink?: boolean;
	ctaLinkType?: CTALinkType;
	children?: React.ReactElement;
	style?: LinkProps['style'];
	className?: string;
	callback?: () => void;
	locale?: NextLinkProps['locale'];
	currentPage?: boolean;
	prefetch?: boolean;
}

export const LinkFeature: React.FC<LinkComponent> = ({
	url,
	name,
	target,
	ariaLabel,
	asText,
	isCTALink = false,
	ctaLinkType = 'arrow',
	children,
	style,
	className,
	callback,
	locale,
	currentPage,
	prefetch = false,
}) => {
	const { publicRuntimeConfig } = getConfig() ?? {};
	const { host } = usePageContext() ?? {};
	const { DOMAIN_PROTOCOL: protocol } = publicRuntimeConfig ?? {};

	const websiteUrl = `${protocol ? `${protocol}://` : ''}${host}`;
	const internalLink = url?.startsWith('/');

	const handleClick = () => {
		if (url[0] !== '#') {
			const urlObject = new URL(url, internalLink ? websiteUrl : undefined);

			const params = new URLSearchParams(urlObject.search);
			for (const [key, value] of params.entries()) {
				if (key != 'action') return;
				if (value === 'cookieRenew' && window?.CookieConsent?.renew != undefined) window.CookieConsent.renew();
			}
		}
		callback && callback();
	};
	return url ? (
		<NextLink href={url} scroll={!url.includes('#')} passHref prefetch={prefetch} locale={locale} legacyBehavior>
			{isCTALink ? (
				<CTALink
					label={name}
					type={ctaLinkType}
					color="dark"
					link={{ url: url, target: target, name: name }}
					onClick={handleClick}
					aria-current={currentPage ? 'page' : undefined}
				/>
			) : (
				<Link
					className={className}
					style={style}
					target={target}
					asText={asText}
					ariaLabel={ariaLabel}
					onClick={handleClick}
					aria-current={currentPage ? 'page' : undefined}
				>
					{children ? children : name}
				</Link>
			)}
		</NextLink>
	) : (
		<></>
	);
};
