import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { usePageContext } from 'application/contexts/PageContext';
import { Breadcrumb } from 'designsystem/components/1-atoms/Breadcrumb';
import { LinkFeature } from 'features/Modules/Link';
import { ExcludedFromCludo } from 'helpers/ExcludedFromCludo';

export const BreadcrumbFeature: React.FC = () => {
	const pageContext = usePageContext();
	const { breadcrumb } = pageContext ?? {};
	const dictionary = useDictionaryContext();

	return breadcrumb?.length > 0 ? (
		<ExcludedFromCludo>
			<Breadcrumb
				className="rs_skip"
				breadcrumbItems={breadcrumb?.map((item, index) => {
					return <LinkFeature key={index} url={item.url} name={item.title} />;
				})}
				label={dictionary.getLabel('BreadcrumbNavigation')}
			/>
		</ExcludedFromCludo>
	) : null;
};
