import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { Logo } from 'designsystem/components/2-molecules/Logo';
import AdstDarkLogo from 'icons/logos/logo-adst-dark.svg';
import AdstLogo from 'icons/logos/logo-adst.svg';
import CrownLogo from 'icons/logos/logo-crown.svg';
import GaeldstDarkLogo from 'icons/logos/logo-gaeldst-dark.svg';
import GaeldstLogo from 'icons/logos/logo-gaeldst.svg';
import IttiDarkLogo from 'icons/logos/logo-itti-dark.svg';
import IttiLogo from 'icons/logos/logo-itti.svg';
import MotorstDarkLogo from 'icons/logos/logo-motorst-dark.svg';
import MotorstLogo from 'icons/logos/logo-motorst.svg';
import SanstDarkLogo from 'icons/logos/logo-sanst-dark.svg';
import SanstLogo from 'icons/logos/logo-sanst.svg';
import SkatDarkLogo from 'icons/logos/logo-skat-dark.svg';
import SkatLogo from 'icons/logos/logo-skat.svg';
import SkmDarkLogo from 'icons/logos/logo-skm-dark.svg';
import SkmLogo from 'icons/logos/logo-skm.svg';
import SktfvDarkLogo from 'icons/logos/logo-sktfv-dark.svg';
import SktfvLogo from 'icons/logos/logo-sktfv.svg';
import SktstDarkLogo from 'icons/logos/logo-sktst-dark.svg';
import SktstLogo from 'icons/logos/logo-sktst.svg';
import ToldstDarkLogo from 'icons/logos/logo-toldst-dark.svg';
import ToldstLogo from 'icons/logos/logo-toldst.svg';
import UfstDarkLogo from 'icons/logos/logo-ufst-dark.svg';
import UfstLogo from 'icons/logos/logo-ufst.svg';
import VurdstDarkLogo from 'icons/logos/logo-vurdst-dark.svg';
import VurdstLogo from 'icons/logos/logo-vurdst.svg';
import NextLink from 'next/link';

export interface LogoFeatureProps {
	dark?: boolean;
	enforceFullLogo?: boolean;
}

export const LogoFeature = ({ dark = false, enforceFullLogo = false }: LogoFeatureProps) => {
	const siteContext = useSiteContext();
	const dictionary = useDictionaryContext();

	const logoSwitch = (): React.ReactNode => {
		switch (siteContext?.theme) {
			case 'ADST':
				return dark ? <AdstDarkLogo /> : <AdstLogo />;
			case 'GAELDST':
				return dark ? <GaeldstDarkLogo /> : <GaeldstLogo />;
			case 'ITTI':
				return dark ? <IttiDarkLogo /> : <IttiLogo />;
			case 'MOTORST':
				return dark ? <MotorstDarkLogo /> : <MotorstLogo />;
			case 'SANST':
				return dark ? <SanstDarkLogo /> : <SanstLogo />;
			case 'SKAT':
				return dark ? <SkatDarkLogo /> : <SkatLogo />;
			case 'SKM':
				return dark ? <SkmDarkLogo /> : <SkmLogo />;
			case 'SKTFV':
				return dark ? <SktfvDarkLogo /> : <SktfvLogo />;
			case 'SKTST':
				return dark ? <SktstDarkLogo /> : <SktstLogo />;
			case 'TOLDST':
				return dark ? <ToldstDarkLogo /> : <ToldstLogo />;
			case 'UFST':
				return dark ? <UfstDarkLogo /> : <UfstLogo />;
			case 'VURDST':
				return dark ? <VurdstDarkLogo /> : <VurdstLogo />;
			case 'WEBGUIDE':
				return dark ? <UfstDarkLogo /> : <UfstLogo />;
			default:
				return dark ? <CrownLogo /> : <CrownLogo />;
		}
	};
	return (
		<>
			<NextLink href={'/'} passHref prefetch={false} legacyBehavior>
				<Logo
					heading={dictionary.getExplainer(siteContext?.theme)}
					linkText={dictionary.getAction('GoToHomePage')}
					logo={logoSwitch()}
					enforceFullLogo={enforceFullLogo}
				/>
			</NextLink>
		</>
	);
};
