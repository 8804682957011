import React from 'react';

interface CustomizeElementProps {
	element: React.ReactElement;
	style: string;
	children?: React.ReactElement;
}

export const customizeElement = ({ element, style, children }: CustomizeElementProps) => {
	return React.cloneElement(element, {
		className: `${element.props?.className ? element.props.className : ''} ${style}`,
		children: children ? children : element.props.children,
	});
};
