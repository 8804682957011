/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import { Button } from 'components/1-atoms/Buttons';
import { SkipToMain } from 'components/1-atoms/SkipToMain';
import { Navigation, NavigationProps } from 'components/3-organisms/Navigation';
import BurgerIcon from 'icons/icon-burgermenu.svg';
import SoegIcon from 'icons/icon-soeg.svg';
import { useState } from 'react';
import styles from './Header.module.scss';

export type HeaderProps = {
	children?: React.ReactNode;
	navItems?: NavigationProps['navigationItems'];
	extraBurgerLinks?: NavigationProps['extraBurgerItems'];
	heading?: string;
	authentication?: React.ReactNode;
	darkLogo?: React.ReactNode;
	enforcedFullLogo?: React.ReactNode;
	logo?: React.ReactNode;
	burgerButtonText?: string;
	goToMainText?: string;
	searchButtonText?: string;
	searchButtonOnClick?: React.MouseEventHandler<HTMLButtonElement>;
	skipToMainText?: string;
	openText?: string;
	closeText?: string;
	topArea?: React.ReactNode;
};

export const Header: React.FC<HeaderProps> = ({
	children,
	navItems,
	extraBurgerLinks,
	heading,
	authentication,
	enforcedFullLogo,
	darkLogo,
	logo,
	burgerButtonText,
	goToMainText,
	searchButtonText,
	searchButtonOnClick,
	skipToMainText,
	openText,
	closeText,
	topArea,
}) => {
	const [burgerActive, setBurgerActive] = useState(false);
	return (
		<header className={styles.Header}>
			<SkipToMain link="#main" title={skipToMainText}>
				{goToMainText}
			</SkipToMain>
			{topArea}
			<div className={styles.Header_printview}>{darkLogo}</div>
			<div className={styles.Header_wrapper}>
				<div className={styles.Header_logo}>{logo ? logo : darkLogo}</div>
				<div className={classNames(styles.Header_navLink, { [styles.Header_navLink___open]: burgerActive })}>
					<Navigation
						navigationItems={navItems}
						extraBurgerItems={extraBurgerLinks}
						style="header"
						onClick={() => setBurgerActive(false)}
						burgerActive={burgerActive}
						heading={heading}
						openText={openText}
						closeText={closeText}
						logoarea={enforcedFullLogo}
					/>
				</div>
				<div className={styles.Header_navItem}>
					{burgerButtonText && (
						<Button
							className={classNames(styles.Header_button, styles.Header_button___menu)}
							type="button"
							onClick={() => setBurgerActive(true)}
						>
							<BurgerIcon className={styles.Header_buttonIcon} />
							<span className={styles.Header_buttonText}>{burgerButtonText}</span>
						</Button>
					)}

					{searchButtonOnClick && (
						<Button className={styles.Header_button} type="button" onClick={searchButtonOnClick}>
							<SoegIcon className={classNames(styles.Header_buttonIcon, styles.Header_buttonIcon___white)} />
							<span className={styles.Header_buttonText}>{searchButtonText} </span>
						</Button>
					)}
					{authentication ? authentication : null}
				</div>
			</div>
			{burgerActive && <div className={styles.Header_backdrop} onClick={() => setBurgerActive(false)}></div>}
			{children}
		</header>
	);
};
