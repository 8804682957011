import { useEffect, useState } from 'react';
import { useBrowserLayoutEffect } from './useBrowserLayoutEffect';

export default function useTransition(ref: React.MutableRefObject<HTMLDivElement>, minHeight?: number): null | typeof TransitionStates {
	const [naturalHeight, setNaturalHeight] = useState(0);
	const doesContentOverflowMinHeight = minHeight < naturalHeight;

	useBrowserLayoutEffect(() => {
		if (ref && minHeight) {
			ref.current.style.display = 'block';
			setNaturalHeight(ref.current.offsetHeight);
			if (minHeight < ref.current.offsetHeight) {
				ref.current.style.height = `${minHeight}px`;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minHeight]);

	useEffect(() => {
		if (ref && minHeight) {
			ref.current.style.height = `${minHeight}px`;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!!minHeight && !doesContentOverflowMinHeight && naturalHeight != 0) return null;

	const TransitionStates = {
		onEnter: () => {
			ref.current.style.height = '';
			setNaturalHeight(ref.current.offsetHeight);
			ref.current.style.height = minHeight ? `${minHeight}px` : '0';
		},
		onEntering: () => {
			ref.current.style.height = `${naturalHeight}px`;
		},
		onEntered: () => {
			ref.current.style.height = '';
		},
		onExit: () => {
			ref.current.style.display = 'block';
			ref.current.style.height = `${ref.current.offsetHeight}px`;
		},
		onExiting: () => {
			ref.current.style.height = minHeight ? `${minHeight}px` : '0';
		},
		onExited: () => {
			ref.current.style.height = minHeight ? `${minHeight}px` : '';
			ref.current.style.display = minHeight ? 'block' : '';
		},
	};

	return TransitionStates;
}
