export const getAbsoluteUrl = (relativeUrl: string, fallbackValue = '') => {
	let url: string;
	const base = document?.location?.origin;
	try {
		url = new URL(relativeUrl, base).toString();
	} catch {
		url = fallbackValue;
	}

	return url;
};
