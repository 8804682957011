import classNames from 'classnames';
import React from 'react';
import styles from './Link.module.scss';

export interface LinkProps extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'style'> {
	children?: React.ReactNode;
	style?: 'primary' | 'sm' | 'arrow' | 'buttonArrow';
	ariaLabel?: string;
	className?: string;
	url?: string;
	asText?: boolean;
	target?: string;
	tabIndex?: number;
	onClick?: () => void;
}

export const Link: React.FC<LinkProps> = React.forwardRef(function Link(
	{ children, style, ariaLabel, className, url, target, asText, tabIndex, onClick, ...linkProps },
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	ref,
) {
	return (
		<>
			{asText ? (
				<p className={classNames(styles.Link, styles[`Link___${style}`], className)}>{children}</p>
			) : (
				<a
					href={url}
					target={target}
					aria-label={ariaLabel}
					className={classNames(styles.Link, styles[`Link___${style}`], className)}
					onClick={onClick}
					tabIndex={tabIndex}
					{...linkProps}
				>
					{children}
				</a>
			)}
		</>
	);
});
