import { IconButton } from 'components/1-atoms/Buttons';
import { LoginModal } from 'components/2-molecules/LoginModal';
import LogInIcon from 'icons/icon-log-in.svg';
import { useEffect, useState } from 'react';
import styles from './Login.module.scss';

export interface LoginOption {
	label?: string;
	link: JSX.Element;
	content?: string;
	hideInCollapse?: boolean;
}
export interface LoginProps {
	loginText: string;
	loginModalContent?: LoginOption[];
	callBack?: () => void;
	show?: boolean;
	hideButton?: boolean;
}
export const Login = ({ loginText, loginModalContent, callBack, show = false, hideButton = false }: LoginProps) => {
	const [showLogin, setShowLogin] = useState(false);
	const [modalTrigger, setModalTrigger] = useState<HTMLElement>();
	const handleLoginClick = (e: React.MouseEvent) => {
		if (!(e.target instanceof HTMLElement)) return;
		if (!loginModalContent) return callBack();
		setModalTrigger(e.target);
		setShowLogin(true);
		e.preventDefault();
	};
	const closeLoginModal = () => {
		setShowLogin(false);
		setTimeout(() => modalTrigger?.focus(), 0);
		callBack();
	};
	useEffect(() => {
		setShowLogin(show);
	}, [show]);

	return (
		<div className={!hideButton ? styles.Login : ''}>
			{!hideButton && (
				<IconButton className={styles.Login_button} iconSize={'auto'} icon={<LogInIcon />} label={loginText} onClick={handleLoginClick} />
			)}
			{loginModalContent && <LoginModal loginModalContent={loginModalContent} show={showLogin} onClose={closeLoginModal} />}
		</div>
	);
};
